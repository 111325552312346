import React from "react"
import Button from "./Button"
import styled from "styled-components"
import variables from "../GlobalVariables"

const Form = styled.form`
  width: 100%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: ${variables.clearColor};
  margin: 0 auto;
  padding: 10px;
`

const Label = styled.label`
  width: 100%;
  max-width: 400px;
  text-align: left;
`

const Input = styled.input`
  border: none;
  box-sizing: border-box;
  border-bottom: 2px ${variables.accentColor} solid;
  background-color: ${variables.greyColor};
  padding: 7px;
  color: ${variables.clearColor};
  width: 100%;
  max-width: 600px;

  &:focus {
    border: 1px solid ${variables.accentColor};
  }
`

const TextArea = styled.textarea`
  font-family: "Poppins", sans-serif;
  width: 100%;
  max-width: 610px;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid ${variables.accentColor};
  border-radius: 4px;
  background-color: ${variables.greyColor};
  color: ${variables.clearColor};
  resize: none;
`

const form = props => {
  return (
    <Form
      name="contact"
      method="post"
      data-netlify="true"
      netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <Label>
        Your Name: <br />
      </Label>
      <Input type="text" name="name" placeholder="Michael Scott" required />

      <br />
      <br />
      <Label>
        Your Email: <br />
        <Input
          type="email"
          name="email"
          placeholder="mscott@gmail.com"
          required
        />
      </Label>
      <br />
      <br />
      <Label>
        Message: <br />
        <TextArea required name="message" placeholder=""></TextArea>
      </Label>

      <p>
        <Button type="submit" width="50%" submit={props.submit}>
          Send Message
        </Button>
      </p>
    </Form>
  )
}

export default form
