import React from "react"
import Container from "../Container/Container"
import styled from "styled-components"
import variables from "../GlobalVariables"
import Title from "../UI/Title"
import ContactForm from "../UI/Form"

const ContactStyle = styled.div`
  margin: 0 auto;
  width: 70%;
  text-align: left;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .Contact-text-side {
    width: 100%;
    padding: 20px;
  }

  .Contact-form-side {
    width: 100%;
  }

  @media (max-width: ${variables.mediumScreen}) {
    width: 90%;
  }

  @media (max-width: ${variables.smallScreen}) {
    width: 90%;
    flex-direction: column;
  }
`

const aboutSection = props => {
  return (
    <ContactStyle id="contact">
      <div className="Contact-text-side">
        <Title size="l">Contact Me</Title>
        <p>
          I cannot hack a social media accounts but I can build awesome sites,
          from a one page portfolio to an ecommerce site, or maybe you are
          looking for a Developer to join your team, or work on a project?
          <br />
          <br />
          If you would like more information or just want to say hello, send me
          an email and I will get back to you as soon as possible.
        </p>
      </div>
      <div className="Contact-form-side">
        <ContactForm />
      </div>
    </ContactStyle>
  )
}

export default aboutSection
