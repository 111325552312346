import React from "react"
import variables from "../GlobalVariables"
import styled from "styled-components"
import { Link } from "gatsby"

const Button = styled.button`
  box-sizing: border-box;
  margin-right: 10px;
  background-color: ${variables.greyColor};
  font-family: "Open Sans", sans-serif;
  color: ${variables.clearColor};
  border: ${variables.accentColor} 1px solid;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 22px;
  -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out, color 500ms ease-in-out;
  padding: 10px 20px;

  &:hover {
    color: ${variables.clearColor};
    border: ${variables.accentColor} 1px solid;
    box-shadow: 0 0 40px 40px ${variables.accentColor} inset;
  }

  @media (max-width: ${variables.smallScreen}) {
  }
`

const button = props => {
  return (
    <a href={props.href} download={props.download} target={"_blank"}>
      <Link to={props.to}>
        <Button type={props.type}>{props.children}</Button>
      </Link>
    </a>
  )
}

export default button
