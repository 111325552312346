import React from "react"
import styled from "styled-components"
import Project from "../../projects.json"
import Title from "../UI/Title"

const ProjectContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`

const ProjectCard = styled.div`
  margin: 10px;
  background: var(--greyColor);
  padding: 20px;
  border-radius: 25px;

  a {
    color: var(--clearColor);
  }

  .project-description {
    margin-bottom: 20px;
  }
`

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  p {
    border: var(--accentColor) 1px solid;
    color: var(--accentColor);
    padding: 5px 15px;
    margin: 3px;
    border-radius: 50px;
    overflow: hidden;
  }

  p:hover {
    background: var(--accentColor);
    color: var(--clearColor);
    transition: 300ms;
  }
`

const Projects = props => {
  let list = Project.map(singleProject => {
    return (
      <ProjectCard key={singleProject.id}>
        <a href={singleProject.url} target="_blank">
          {/* <img src={singleProject.imageURL} alt={singleProject.title} /> */}
          <Title size="m">{singleProject.title}</Title>
          <p className="project-description">{singleProject.description}</p>
          <StyledRow>
            {singleProject.technologiesUsed.map((tech, index) => {
              return <p key={index}>{tech}</p>
            })}
          </StyledRow>
        </a>
      </ProjectCard>
    )
  })
  return <ProjectContainer>{list}</ProjectContainer>
}

export default Projects
