import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import variables from "../GlobalVariables"

const WholeThing = styled.div`
  background: var(--greyColor);
  box-sizing: border-box;
  margin: 20px auto;
  width: 70%;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 10px;
  transition: ease 0.2s;

  a {
    color: ${variables.clearColor};
  }

  &:hover {
    transition: ease 0.2s;
    box-shadow: 0px 0px 34px 8px rgba(0, 0, 0, 0.42);
    transform: scale(1.1);
  }

  @media screen and (max-width: ${variables.mediumScreen}) {
    width: 90%;
  }

  @media screen and (max-width: ${variables.smallScreen}) {
    flex-direction: column;
    width: 90%;
  }
`

const Bottom = styled.div`
  background: ${variables.greyColor};
  padding: 10px;

  &:hover {
    background: ${variables.greyColor};
  }
`

const PostTag = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  p {
    border: ${variables.accentColor} 1px solid;
    color: ${variables.accentColor};
    padding: 5px 15px;
    margin: 3px;
    border-radius: 50px;
    overflow: hidden;
  }

  p:hover {
    background: ${variables.accentColor};
    color: ${variables.clearColor};
    transition: 300ms;
  }

  @media (max-width: ${variables.smallScreen}) {
    display: none;
  }
`

const BlogCard = props => {
  const postData = props.data

  const blogPostTags = postData.tags.slice(0, 3).map(postTag => (
    <p className="tag-cards" key={postTag.id}>
      #{postTag.name}
    </p>
  ))

  return (
    <WholeThing>
      <Link to={props.to}>
        <h2>{postData.title}</h2>
        <p>Published: {postData.published_at}</p>
        <p>{postData.excerpt}</p>
        <PostTag>{blogPostTags}</PostTag>
      </Link>
    </WholeThing>
  )
}

export default BlogCard
