import React from "react"

import styled from "styled-components"
import variables from "../GlobalVariables"
import ProjectList from "../Projects/Projects"
import Title from "../UI/Title"

const ProjectStyle = styled.div`
  margin: 0 auto;
  width: 70%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .GHLink {
    color: ${variables.accentColor};
  }

  @media (max-width: ${variables.mediumScreen}) {
    width: 90%;
  }

  @media (max-width: ${variables.smallScreen}) {
    width: 90%;
    flex-direction: column;
  }
`

const aboutSection = props => {
  return (
    <ProjectStyle id="projects">
      <Title size="l">Projects</Title>
      <p>
        Check out my{" "}
        <a
          className="GHLink"
          href="https://github.com/djimenez017?tab=repositories"
          target="_blank"
        >
          Github
        </a>{" "}
        for these and more repositories.
      </p>
      <ProjectList />
    </ProjectStyle>
  )
}

export default aboutSection
