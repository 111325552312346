import React from "react"

import styled from "styled-components"
import variables from "../GlobalVariables"

const ContainerStyles = styled.div`
  display: flex;
  align-items: center;
  align-content: center;

  @media (max-width: ${variables.mediumScreen}) {
  }

  @media (max-width: ${variables.smallScreen}) {
    flex-direction: column;
  }
`

const Container = props => {
  return <ContainerStyles>{props.children}</ContainerStyles>
}

export default Container
