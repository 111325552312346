import React from "react"
import styled from "styled-components"
import variables from "../GlobalVariables"
import Title from "../UI/Title"
import Button from "../UI/Button"
import BlogSectionCard from "../BlogItems/blogSectionPost"

const AboutStyle = styled.div`
  margin: 0 auto;
  width: 70%;
  text-align: left;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .PostSection {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: ${variables.mediumScreen}) {
    width: 90%;
  }

  @media (max-width: ${variables.smallScreen}) {
    flex-direction: column;
    width: 90%;
  }
`

const aboutSection = props => {
  let blogPosts = props.post
  let Posts = blogPosts.slice(0, 2).map(Post => {
    return (
      <BlogSectionCard
        data={Post.node}
        key={Post.node.id}
        to={`/blog/${Post.node.slug}`}
      />
    )
  })

  return (
    <AboutStyle id="blog">
      <div>
        <Title size="l">Blog</Title>
        <p>
          Check out some of my Posts! Like them? Share them with the community!
          <br />
        </p>
        <Button to="/blog">More Posts</Button>
      </div>
      <div className="PostSection">{Posts}</div>
    </AboutStyle>
  )
}

export default aboutSection
