import React from "react"
import styled from "styled-components"

const SVGStyle = styled.div`
  margin: 0 auto;
  width: 450px;

  .st0 {
    fill: #ff7315;
    fill-opacity: 0;
    stroke: #ff7315;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 3000;
    stroke-dashoffset: 0;
    animation: dash 4s linear forwards;
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 3000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`

const logo = props => {
  return (
    <SVGStyle>
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 640 467"
      >
        <polygon
          className="st0"
          points="362.59,5.07 191.64,5.07 6.84,327.54 377.4,327.54 448.73,222.39 361.65,222.39 335.82,260.7 
	121.42,260.7 230.73,71.31 335.32,70.89 384.84,122.35 475.78,122.35 "
        />
        <polygon
          className="st0"
          points="277.26,461.6 448.2,461.6 633,139.14 262.44,139.14 191.11,244.28 278.2,244.28 304.02,205.97 
	518.43,205.97 409.12,395.36 304.53,395.78 255,344.33 164.06,344.33 "
        />
      </svg>
    </SVGStyle>
  )
}

export default logo
