import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/Container/Container"
import LandingPage from "../components/siteSections/landingPage"
import AboutSection from "../components/siteSections/aboutSection"
import ProjectSection from "../components/siteSections/projectsSection"
import BlogSection from "../components/siteSections/blogSection"
import ContactSection from "../components/siteSections/contactSection"

const IndexPage = props => {
  const imageSkills = props.data.allFile.edges
  let blogPosts = props.data.allGhostPost.edges
  // const blogPostTags = props.data.allGhostTag.nodes

  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <LandingPage />
      </Container>

      <Container>
        <AboutSection skills={imageSkills} />
      </Container>

      <Container>
        <ProjectSection />
      </Container>

      <Container>
        <BlogSection post={blogPosts} />
      </Container>

      <Container>
        <ContactSection />
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    __typename
    allFile(filter: { relativeDirectory: { eq: "skills" } }) {
      edges {
        node {
          childImageSharp {
            id
            original {
              src
            }
          }
          name
        }
      }
    }

    allGhostPost(sort: { fields: created_at, order: DESC }) {
      edges {
        node {
          slug
          published_at(formatString: "MMM DD YYYY")
          authors {
            location
            name
          }
          title
          excerpt
          id
          feature_image
          canonical_url
          page
          tags {
            description
            id
            name
          }
        }
        previous {
          id
          slug
          excerpt
          title
        }
        next {
          id
          slug
          excerpt
          title
        }
      }
    }
    allGhostTag {
      nodes {
        name
        id
      }
    }
  }
`
