import React from "react"
import styled from "styled-components"
import variables from "../GlobalVariables"

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;

  @media (max-width: ${variables.smallScreen}) {
    width: 95%;
  }
`
const SkillContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: calc(100% / 3);
  text-align: center;

  img {
    width: 60%;
    height: auto;
    padding: 10px;
  }

  @media (max-width: ${variables.mediumScreen}) {
    width: calc(100% / 4);
  }

  @media (max-width: ${variables.smallScreen}) {
    width: calc(100% / 3);

    img {
      width: 80%;
    }
  }
`

const SkillImages = props => {
  const listOfImages = props.images.map(image => {
    const singleImage = image.node.childImageSharp.original.src
    const Titles = image.node.name
    const titleCleanUp = Titles.substring(Titles.indexOf("-") + 1)
    return (
      <SkillContainer key={image.node.childImageSharp.id}>
        <img className="skill-container" src={singleImage} alt={titleCleanUp} />
      </SkillContainer>
    )
  })

  return <SkillsContainer>{listOfImages}</SkillsContainer>
}

export default SkillImages
