import React from "react"

import styled from "styled-components"
import variables from "../GlobalVariables"
import Skills from "../UI/SkillImages"
import Title from "../UI/Title"
import Button from "../UI/Button"
import Resume from "../../images/Daniel Jimenez - Web Developer.pdf"

const AboutStyle = styled.div`
  margin: 0 auto;
  width: 70%;
  text-align: left;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  .button-row {
    display: flex;
  }

  .text-side {
    width: 100%;
  }

  .text-paragraph {
    letter-spacing: 0.15em;
  }

  .skills-side {
    width: 100%;
  }

  @media (max-width: ${variables.mediumScreen}) {
    width: 90%;
  }

  @media (max-width: ${variables.smallScreen}) {
    width: 90%;
    flex-direction: column;

    .text-side {
    }

    .skills-side {
      display: none;
    }
  }
`

const aboutSection = props => {
  return (
    <AboutStyle id="about">
      <div className="text-side">
        <Title size="l">About Me</Title>
        <p className="text-paragraph">
          I graduated from California State University of Northridge with a
          Bachelors of Science in computer information technology, and a minor
          in graphic design.
          <br />
          <br /> Ever since I took my first Web Design course I was hooked and I
          knew it was my calling. My goal is to create user experiences using
          modern frontend tools. <br />
          <br /> Feel free to reach out to me to talk about anything from
          movies, video games, Star Wars or maybe a project that you may have in
          mind.
        </p>
        <div className="button-row">
          <Button href={Resume} download>
            My Resume
          </Button>
          <Button to="/about">View More</Button>
        </div>
      </div>
      <div className="skills-side">
        <Skills images={props.skills} />
      </div>
    </AboutStyle>
  )
}

export default aboutSection
