import React from "react"

import styled from "styled-components"
import variables from "../GlobalVariables"
import Title from "../UI/Title"
import Button from "../UI/Button"
import OutlineLogo from "../UI/LogoOutline"

const LandingStyle = styled.div`
  margin: 0 auto;
  width: 70%;
  text-align: left;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;

  .hero-test {
    margin: 0 auto;
  }

  .mobileMode {
    width: 50%;
  }

  .small-text {
    font-size: 14px;
    color: gray;
  }

  .Button-row {
    display: flex;
    width: 100%;
  }
  @media (max-width: ${variables.mediumScreen}) {
    width: 90%;
  }

  @media (max-width: ${variables.smallScreen}) {
    width: 90%;
    flex-direction: column;
    text-align: center;

    .hero-test {
    }

    .Button-row {
      justify-content: center;
    }

    .mobileMode {
      display: none;
    }
  }
`

const landingPage = props => {
  return (
    <LandingStyle id="main">
      <div className="hero-text">
        <p className="small-text">Hello there 👋 , my name is</p>
        <Title>Daniel Jimenez</Title>

        <p>I am a Web developer from Los Angeles, California.</p>
        <br />
        <div className="Button-row">
          <Button to={"/#about"}>About me</Button>
          <Button to={"/#contact"}>Get in Touch</Button>
        </div>
      </div>
      <div className="mobileMode">
        <OutlineLogo></OutlineLogo>
      </div>
    </LandingStyle>
  )
}

export default landingPage
